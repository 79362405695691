import React, { useState, ChangeEvent } from 'react';
import cn from "classnames";
import { GenderType } from "src/feature/GenderSelect/type";

interface GenderSelectorProps {
  onChange: (gender: GenderType) => void;
  defaultValue?: GenderType;
  classNames?: string;
}

export const GenderSelector = ({ onChange, defaultValue = 'male', classNames }: GenderSelectorProps) => {
  const [gender, setGender] = useState<GenderType>(defaultValue);

  const handleGenderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newGender = event.target.value as GenderType;
    setGender(newGender);
    onChange(newGender);
  };

  return (
    <>
      <style>{`
        input[type='radio'] + div  {
          border: 2px solid rgb(255, 255, 255); 
        }

        input[type='radio']:checked + div  {
          border: 4px solid #FF335F; 
        }
      `}</style>
      <div className={classNames}>
        <div className='flex flex-col'>
          <span className='mb-[5px] w-full text-[12px] leading-[15px] text-[#4f4f4f]'>Пол</span>
          <label className='flex mb-4 text-[16px] leading-[14px] items-center'>
            <input
              type="radio"
              id="male"
              value="male"
              checked={gender === 'male'}
              onChange={handleGenderChange}
              className='hidden'
            />
            <div
              className={cn(
                'flex h-6 w-6 items-center justify-center rounded-full border mr-3',
              )}
            />
            Мужской
          </label>

          <label className='flex'>
            <input
              type="radio"
              id="female"
              value="female"
              checked={gender === 'female'}
              onChange={handleGenderChange}
              className='hidden'
            />
            <div
              className={cn(
                'flex h-6 w-6 items-center justify-center rounded-full border mr-3',
              )}
            />
            Женский
          </label>
        </div>
      </div>
      </>
  );
}
