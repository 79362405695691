import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from 'src/entity/Container';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import BasketService from 'src/shared/api/basket/BasketService';
import { ProductType } from 'src/shared/api/catalog';
import PaymentService from 'src/shared/api/payment/PaymentService';
import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/kit/trash.svg';
import { ReactComponent as TrashXIcon } from 'src/shared/assets/icons/kit/trash-x.svg';
import { ReactComponent as SaleBg } from 'src/shared/assets/icons/kit/sale-bg.svg';
import { ReactComponent as EmptyShoppingCartIcon } from 'src/shared/assets/images/empty-shopping-cart.svg';
import { routesEnum } from 'src/shared/const';
import { addSpaces, declOfNum } from 'src/shared/lib/utils';
import { BasketContext, UserContext } from 'src/shared/store';
import { Button, CheckBox, DiscountPrice } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

export const BasketPage: FC = () => {
  const { user } = useContext(UserContext);
  const { basket, setIsBasketLoading, setBasket, isBasketLoading } = useContext(BasketContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChecked, setIsLoadingChecked] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        setIsBasketLoading(true);

        const { data } = await BasketService.getBasket(user.id);
        setBasket(data);
      } catch (e) {
        setBasket([]);
      } finally {
        setIsBasketLoading(false);
      }
    })();
  }, [user]);

  const isAllSelected = useMemo(() => {
    if (!basket.length) {
      return true;
    }

    return !basket.some(el => !el.checked);
  }, [basket]);

  const handleGetToken = async () => {
    if (isLoading || !user) {
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await PaymentService.getConfirmationToken({
        email: user.email,
        userId: user.id,
      });
      navigate(`/payment/${data.confirmation_token}`);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const summ = useMemo(() => {
    const res = { count: 0, price: 0 };

    if (!basket.length) {
      return res;
    }

    basket.forEach(el => {
      if (el.checked) {
        res.price += el.amount * +(el.newPrice.replace(',', '.') || 0);
        res.count += el.amount;
      }
    });

    return { count: +res.count.toFixed(2), price: +res.price.toFixed(2) };
  }, [basket]);

  const selectAll = async () => {
    if (isLoadingChecked || !user) {
      return;
    }

    try {
      setIsLoadingChecked(true);
      const products = basket.map(el => ({ ...el, checked: !isAllSelected }));
      await BasketService.addProductToBasket(user.id, products);
      setBasket(prev => prev.map(el => ({ ...el, checked: !isAllSelected })));
    } finally {
      setIsLoadingChecked(false);
    }
  };

  const handleClearBasket = async () => {
    if (isLoading || !user?.id) {
      return;
    }

    try {
      setIsLoading(true);
      await BasketService.addProductToBasket(
        user.id,
        basket.map(el => ({ ...el, amount: 0, removed: true })),
      );
      setBasket([]);
    } finally {
      setIsLoading(false);
    }
  };

  const selectOne = async (product: ProductType) => {
    if (isLoadingChecked || !user) {
      return;
    }

    try {
      setIsLoadingChecked(true);

      await BasketService.addProductToBasket(user.id, [{ ...product, checked: !product.checked }]);
      setBasket(prev =>
        prev.map(el => (el.id === product.id ? { ...el, checked: !product.checked } : el)),
      );
    } finally {
      setIsLoadingChecked(false);
    }
  };

  const removeFromBasket = async (product: ProductType) => {
    if (!user?.id) {
      return;
    }

    try {
      await BasketService.addProductToBasket(user.id, [{ ...product, amount: 0, removed: true }]);

      setBasket(prev => prev.filter(el => el.id !== product.id));
    } catch (e) {}
  };

  const onFavorite = (product: ProductType) => {
    setBasket(prev =>
      prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };
  
  const content = (
    <>
      <div className="group flex cursor-pointer items-center сol-start-1 row-start-2 col-end-2 row-end-3 pl-4 xl:col-start-1 xl:col-end-2 xl:row-start-4 xl:row-end-5 xl:p-0" onClick={selectAll}>
        <CheckBox
          isActive={isAllSelected}
          size="m"
          activeBorder="primary"
          classNames="border-primary-100"
        />
        <div className="font-default-regular ml-2 text-sm">Выбрать все</div>
      </div>

      <div className='flex сol-start-2 row-start-2 col-end-3 row-end-3 pr-4 d-xs:pr-0 h-[40px] justify-end xl:col-start-3 xl:col-end-4 xl:row-start-4 xl:row-end-5'>
        <Button
          className="group hover:text-primary-100 transition-colors text-[#FF335F] border border-[#ff335f] h-[40px] d-xs:px-11"
          variant="transparent"
          size="sm"
          onClick={handleClearBasket}
        >
          <TrashXIcon className="mr-2 h-4 w-4 fill-[#787878]" />
          Очистить корзину
        </Button>
      </div>
      <div className="bg-[#262626] pt-2 w-full сol-start-1 row-start-3 col-span-2 row-end-4 xl:col-start-1 d-xs:col-end-2 d-xs:row-start-6 d-xs:row-end-7 d-xs:p-5 xl:rounded-[10px] pl-3.5 d-xs:pl-5 pr-3.5 d-xs:pr-5 d-xs:pt-2">
        {basket.map(product => (
          <div key={product.id} className="pb-[19px] border-b-1 pt-2.5 border-colors-dark divide-y last:divide-y-0">
            <BasketItem
              product={product}
              onFavorite={onFavorite}
              select={selectOne.bind(null, product)}
              remove={removeFromBasket.bind(null, product)}
            />
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center justify-between row-start-4 row-end-5 col-span-2 col-start-1 col-end-3 px-4 pb-20 d-xs:row-start-6 d-xs:row-end-7 d-xs:col-start-3 d-xs:col-end-4 d-xs:justify-start d-xs:pb-0 d-xs:px-0">
        <div className="w-full rounded-[20px] bg-[#000000] px-4 py-5 d-xs:py-[20px] d-xs:px-[30px]">
          <div className="font-default-regular mb-[27px] flex justify-between text-[14px] leading-[14px] colors-gray-dark">
            <div>{`${summ.count} ${declOfNum(summ.count, ['товар', 'товара', 'товаров'])}`}</div>
            <div className="ml-auto">{`${addSpaces(+summ.price.toFixed(2))} ₽`}</div>
          </div>

          <div className="font-default-regular mb-[27px] flex justify-between items-center text-[20px] leading-[14px]">
            <div>Итого:</div>
            <div className="ml-auto ">{`${addSpaces(+summ.price.toFixed(2))} ₽`}</div>
          </div>

          <Button
            className="w-full justify-center text-[14px]"
            variant={!basket.some(el => el.checked) ? 'disabled' : 'primary'}
            size="s"
            disabled={isLoading || !basket.some(el => el.checked)}
            onClick={handleGetToken}
          >
            {isLoading ? <Loader classNames="w-5 h-5" /> : 'Перейти к оформлению'}
          </Button>
        </div>
        <Link to={'/delivery_details'} className="d-xs:px-[30px] flex text-[#FF335F] text-[16px] leading-[14px] d-xs:text-[20px] d-xs:leading-[20px] justify-end ml-auto mt-3 d-xs:mt-3">Детали доставки</Link>
      </div>
    </>
  );

  return (
    <Container
      head={
        <>
          <div className="font-default-regular flex align-middle leading-[38px] text-center text-[24px] d-xs:text-[36px]
          сol-start-1 row-start-1 col-end-2 row-end-2
          xl:col-start-1 xl:col-end-2 xl:row-start-2 xl:row-end-3
          pl-4 xl:p-0"> {!!basket.length && 'Корзина'}</div>
          {!!basket.length && !isBasketLoading && (
            <div className="font-default-regular d-xs:w-full flex сol-start-2 row-start-1 col-end-3 row-end-2 ml-auto pr-4 align-baseline justify-between d-xs:pl-[30px] xl:col-start-3 xl:col-end-4 xl:row-start-2 xl:row-end-3">
              <div className='flex items-center'>Итого:</div>
              <div className='flex items-center min-w-[70px] max-w-[120px] pl-[8px] justify-end'>{`${addSpaces(+summ.price.toFixed(2))} ₽`}</div>
            </div>
          )}
        </>
      }
      offsetX={false}
      classNames="grid gap-x-0 gap-y-6 grid-flow-row my-custom-grid t-sm:px-[20px] d-xl:px-0 xl:gap-y-0 xl:pt-0 h-full pt-[84px]
      grid-cols-[160px_1fr]
      xl:grid-cols-[1fr_30px_385px]
      grid-rows-[38px_40px_min-content_min-content_40px]
      xl:grid-rows-[175px_38px_40px_40px_30px_auto_1fr_81px_20px]"
    >
      {isBasketLoading && <div className="flex flex-1 items-center justify-center">
        <Loader classNames="w-32 h-32 text-primary-100" />
      </div>}

      {!basket.length && !isBasketLoading ? (
        <div className="mx-auto flex w-fit flex-col items-center py-8 col-start-1 col-end-3 row-start-1 row-end-5 xl:col-end-4 xl:row-end-10 justify-center" >
          <div className="mb-8 h-[154px] w-[154px]">
            <EmptyShoppingCartIcon/>
          </div>

          <div className="font-default-medium mb-8 text-[20px] leading-[14px]">В корзине пусто</div>

          {!user && (
            <Button
              className="w-48 h-10 justify-center"
              size={'s'}
              variant="primary"
              onClick={() => navigate(routesEnum.LOGIN)}
            >
              Войти
            </Button>
          )}

          {user && !basket.length ? (
            <Button
              className="w-48 h-10 justify-center"
              size={'s'}
              variant="primary"
              onClick={() => navigate(routesEnum.CATALOG)}
            >
              В каталог
            </Button>
          ) : null}
        </div>
      ) : null}

      {!isBasketLoading && basket.length ? content : null}
    </Container>
  );
};

interface BasketItemProps {
  product: ProductType;
  select(): void;
  remove(): void;
  onFavorite(product: ProductType): void;
}
function BasketItem({ product, select, remove, onFavorite }: BasketItemProps) {
  const serviceActivationParam = product?.parameters?.find(
    (param) => param.parameterName === 'Сервис активации'
  );
  const regionActivationParam = product?.parameters?.find(
    (param) => param.parameterName === 'Регион'
  );
  
  const serviceActivationValue = serviceActivationParam?.parameterValues?.[0]?.valueName || '';
  const regionActivationValue = regionActivationParam?.parameterValues?.[0]?.valueName || '';  
  return (
    <div key={`basket-${product.id}`} className="grid
    grid-cols-[14px_73px_12px_120px_1fr_12px_21px]
    grid-rows-[8px_16px_12px_14px_12px_38px_12px_35px]
    d-xs:grid-cols-[14px_73px_40px_1fr_120px_1fr_25px_21px]
    d-xs:grid-rows-[29px_7px_6px_8px_8px_5px_6px_29px]">
      <Link
        to={`/product/${product.id}`}
        className="relative сol-start-1 row-start-1 col-span-3 row-span-6 col-end-4 row-end-7 d-xs:row-end-9 pt-2 pl-3.5"
      >
        <CheckBox
          isActive={product.checked}
          onClick={e => {
            e?.preventDefault();
            select();
          }}
          classNames="border-primary-100 absolute top-0 left-0 z-30 -translate-x-0 -translate-y-0"
          size="m"
          activeBorder="primary"
        />
        <img src={product.image} className='object-cover h-full' alt='' width='73px' height='90px' />
        {product.discount > 0 && <div className='absolute left-4 bottom-[0]'>
          <div className='relative'>
            <SaleBg />
            <span className='absolute right-[5px] bottom-[0] text-[10px]'>{product.discount} %</span>
          </div>
        </div> }
      </Link>

      <div className="flex items-center сol-start-3 row-start-4 col-end-5 row-end-5 d-xs:col-start-6 d-xs:col-end-7 d-xs:pl-4 d-xs:row-start-3 d-xs:row-end-6 justify-center xl:px-[70px]">
        {product.discount && product.discount.toString() !== '0' && (
          <div className="flex items-center gap-2 mr-auto d-xs:mr-[50px]">
            <DiscountPrice
              price={product.oldPrice}
              classNames='font-default-medium text-[#a6a6a6] text-base leading-none  d-xs:text-xl'
              size='sm'
            />
          </div>
        )}
        <div className="font-default-medium text-lg leading-5 d-xs:text-xl d-xs:ml-auto">
          {`${addSpaces(+(+product.newPrice.replace(',', '.') * product.amount).toFixed(2))} ₽`}
        </div>
      </div>

      <div className="font-default-medium truncate break-words break-all text-base leading-none сol-start-4 col-span-2 row-start-2 col-end-6 row-end-3 d-xs:row-start-3 d-xs:row-end-6 d-xs:col-start-4 d-xs:col-end-5 d-xs:pr-2">
        {product.name}
      </div>
      <div className="font-default-regular сol-start-2 col-span-4 row-start-8 col-end-6 row-end-9 d-xs:row-start-7 d-xs:row-end-9 d-xs:col-start-4 d-xs:col-end-5 d-xs:pr-2 flex flex-col gap-[7px]">
        <div className="break-words break-all text-sm text-textLighter leading-none truncate">Сервис активации: {serviceActivationValue}</div>
        <div className="break-words break-all text-sm text-textLighter leading-none truncate">Регион активации: {regionActivationValue}</div>
      </div>
      <ProductAddButton product={product} className="сol-start-4 col-end-5 row-start-6 row-end-7 d-xs:row-start-2 d-xs:row-end-6 d-xs:col-start-5 d-xs:col-end-5" />

      <div className="flex flex-col сol-start-7 col-end-8 row-start-2 row-span-5 row-end-7 justify-between d-xs:col-start-7 d-xs:col-end-8 d-xs:row-start-1 d-xs:row-span-5 d-xs:row-end-9">
        <FavoriteButton
          data={product}
          onClick={onFavorite}
          size="m"
          classNames="max-d-xs:h-5 max-d-xs:w-5 fill-primary-100"
        />
        <button className="group" onClick={remove}>
          <TrashIcon className="h-6 w-6 fill-[#787878] transition-all group-hover:fill-primary-200 max-d-xs:h-5 max-d-xs:w-5" />
        </button>
      </div>
    </div>
  );
}
