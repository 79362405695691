import { FC, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { UserContext } from 'src/app/providers/UserProvider';
import { ProfileNavigation } from 'src/feature/ProfileNavigation';

import { routesEnum } from 'src/shared/const';
import { Loader } from 'src/shared/ui/Loader';
import { HeaderBanner } from 'src/shared/assets/icons/kit/HeaderBanner';

import { LeftIcon } from 'src/shared/assets/icons/kit/LeftIcon';

import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as UserIcon } from 'src/shared/assets/icons/kit/user.svg';

export const Header: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, isUserLoading } = useContext(UserContext);

  const openMenu = () => setIsMenuOpen(false);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <header
      className="max-w-[1720px] w-full py-[12px] fixed top-0 left-1/2 -translate-x-1/2 z-50 grid grid-cols-[1fr_180px_1fr] grid-rows-[3px_36px_3px] gap-[0px_38px] px-4 d-xl:px-0"
    >
      {!!user?.id && pathname !== '/' && (
        <div
          className="z-1000 flex md:hidden h-[36px] w-[36px] cursor-pointer flex-col items-center justify-center rounded-lg bg-darkGray justify-self-start col-start-1 col-end-2 row-start-2 row-end-3"
          onClick={() => navigate(-1)}
        >
          <LeftIcon />
        </div>
      )}

      <Link
        to={'/'}
        className="cursor-pointer transition-colors hover:text-primary-100 col-start-2 col-end-3 row-start-1 row-end-4 row-span-3"
      >
        <HeaderBanner />
      </Link>



      {user && (
        <div
          className="text-[14px] leading-5 z-1000 cursor-pointer rounded-lg bg-darkGray p-[6px] d-xs:px-[13px] d-xs:py-2 justify-self-end col-start-3 col-end-4 row-start-2 row-end-3"
          onClick={() => {
            if (!isUserLoading && !user) {
              navigate(routesEnum.LOGIN);
            }
            if (user) {
              navigate(routesEnum.PROFILE_ORDERS);
            }
          }}
        >
          <UserIcon className="md:hidden" />
          {isUserLoading && <Loader classNames="w-4 h-4 mx-8" />}
          {!isUserLoading && user && <div className="hidden md:flex">{user.name}</div>}
          {!isUserLoading && !user && 'Войти'}
        </div>
      )}

      {isMenuOpen &&
        createPortal(
          <div className="fixed inset-0 z-2000 bg-bgPrimary p-4 text-textPrimary">
            <div className="mb-8 flex items-center justify-between">
              <div className="font-default-medium text-xl">Меню</div>

              <button className="h-5 w-5 text-textPrimary" onClick={openMenu}>
                <CloseIcon className="p-0.5" />
              </button>
            </div>

            <ProfileNavigation onClose={closeMenu} />
          </div>,
          document.body,
        )}
    </header>
  );
};
