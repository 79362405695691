import React, {useContext, useEffect, useState} from 'react';
import { Link } from "react-router-dom";

import { useIsMobile } from "src/shared/lib/hooks/useIsMobile";

import { ProfileNavigation } from "src/feature/ProfileNavigation";

import { Container } from "src/entity/Container";

import { Button } from "src/shared/ui";
import { GenderSelector } from "src/feature/GenderSelect";


import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/kit/check.svg';
import AuthService from "src/shared/api/auth/AuthService";

import {useForm} from "react-hook-form";
import { ChangeUsersDataInterface } from "src/shared/api/auth/types";
import { UserContext } from "src/app/providers/UserProvider";
import cn from "classnames";
import TokenService from "src/shared/api/token/TokenService";
import { GenderType } from "src/feature/GenderSelect/type";

export const PersonalInformation = () => {
  const [selectedGender, setSelectedGender] = useState<GenderType>('male');
  const { isMobile } = useIsMobile();
  const { user, setUser, setIsUserLoading, isUserLoading } = useContext(UserContext);
  const [login, setLogin] = useState('');

  useEffect(() => {
    if (user) setLogin(user.name);
  }, [])

  useEffect(() => {
    (async () => {
      const tokens = TokenService.getTokens();

      if (!tokens) {
        return setIsUserLoading(false);
      }

      try {
        const { data } = await AuthService.getUser();

        setUser(data);
      } catch (e) {
      } finally {
        setIsUserLoading(false);
      }
    })();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ChangeUsersDataInterface>({
    defaultValues: {
      gender: selectedGender,
      email: '',
      phone: '',
      login: login,
      isPersonalDataProcessingConsentGiven: false,
      isEmailMarketingConsentGiven: false,
    },
  });

  const onSubmit = (data) => {
    AuthService.changeUsersData(data);
  };

  const handleGenderChange = (gender: GenderType) => {
    setSelectedGender(gender);
  };

  return (
    <>
      <style>{`
        input[type='checkbox'] + div  {
          border: 1px solid #DFE0E3;
        }
        
        input[type='checkbox'] + div>svg  {
          display: none
        }

        input[type='checkbox']:checked + div  {
          background-color: #FF335F;
          border: 1px solid #FF335F;
        }
        
        input[type='checkbox']:checked + div>svg  {
          display: block
        }
      `}</style>
      <Container offsetX={false}>
        <div className="flex flex-1 d-xs:px-4 d-xl:px-0">
          <div className="hidden h-full w-72 flex-shrink-0 d-xs:block">
            <ProfileNavigation />
          </div>

          <div className='flex flex-col w-full pt-[84px] px-4 pb-[110px] d-xs:px-0'>
            <div className="flex flex-1 flex-col d-xs:ml-6 bg-[#262626] rounded-[20px] py-5 px-4 d-xs:p-[40px]">
              <div className="font-default-medium text-[24px] leading-[34px] mb-6">Вы вошли в профиль</div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <GenderSelector
                  classNames='mb-[28px]'
                  defaultValue="female"
                  onChange={handleGenderChange}
                />

                <label className='flex flex-col grey-900 max-w-[475px] w-full xl:self-center mb-[16px]'>
                  <span className='mb-[5px] text-left text-[12px] leading-[15px] text-[#4f4f4f]'>Номер телефона</span>
                  <input
                    type="tel"
                    {...register('phone', {
                      pattern: {
                        value:  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
                        message: 'Неверный формат телефона',
                      },
                    })}
                    multiple
                    placeholder="Введите номер телефона"
                    className='rounded-[10px] shadow-[inset_0px_5px_10px_rgba(0,0,0,0.7)] w-full border-solid border py-[9px] px-3.5 grey-900 border-[rgba(217,218,218,0.6)] xl:max-w-[475px]'
                  />
                </label>

                <label className='flex flex-col grey-900 max-w-[475px] w-full xl:self-center mb-[32px]'>
                  <span className='mb-[5px] text-left text-[12px] leading-[15px] text-[#4f4f4f]'>E-mail</span>
                  <input
                    type="email"
                    {...register('email', {
                      required: 'Введите email',
                    })}
                    multiple
                    placeholder="Введите e-mail"
                    className='rounded-[10px] shadow-[inset_0px_5px_10px_rgba(0,0,0,0.7)] w-full border-solid border py-[9px] px-3.5 grey-900 border-[rgba(217,218,218,0.6)] xl:max-w-[475px]'
                  />
                </label>

                <label className='flex mb-[20px]'>
                  <input
                    type="checkbox"
                    {...register('isPersonalDataProcessingConsentGiven', {
                      required: 'Необходимо подтвердить согласие на обработку данных',
                    })}
                    className='hidden'
                  />
                  <div className='bg-bgPrimary h-6 w-6 border-bgPrimary flex items-center justify-center rounded-[5px] border group-hover:border-primary-100 flex-shrink-0 cursor-pointer transition-all'>
                    <CheckIcon className={'stroke-textPrimary transition-transform h-3.5 w-3.5'}/>
                  </div>
                  <div className='text-[12px] self-center ml-[13px] leading-[18px]'>Я соглашаюсь на обработку персональных данных<br /> в соответствии с <Link className='text-[#FF335F]' to={'/'}>условиями использования сайта, Политикой обработки персональных данных</Link> и получение сообщений в процессе обработки заказа</div>
                </label>

                <label className='flex'>
                  <input type="checkbox" {...register('isEmailMarketingConsentGiven')} className='hidden'/>
                  <div className='bg-bgPrimary h-6 w-6 border-bgPrimary flex items-center justify-center rounded-[5px] border group-hover:border-primary-100 flex-shrink-0 cursor-pointer transition-all'>
                    <CheckIcon className={'stroke-textPrimary transition-transform h-3.5 w-3.5'}/>
                  </div>
                  <div className='text-[12px] ml-[13px] leading-[18px]'>Получать <Link className='text-[#FF335F]' to={'/'}>информацию о скидках, специальных предложениях и акциях</Link>, которые организуют продавцы, партнёры и ООО «Маркетплейс».</div>
                </label>

                {!isMobile && <Button
                  type={"submit"}
                  className={cn(
                    "justify-center mt-[40px] max-w-[300px] w-full",
                    isValid  ? 'bg-[#FF335F]' : 'bg-[#812539]'
                  )}
                  size="lg"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid}
                >
                  Сохранить изменения
                </Button>
                }
              </form>
            </div>

            {isMobile && <Button
              className="w-full justify-center mt-6 bg-[#812539]"
              type={"submit"}
              size="s"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            >
              Сохранить изменения
            </Button>}
          </div>
        </div>
      </Container>
    </>
  );
};
