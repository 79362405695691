import React, { useState } from 'react';
import { useSelector } from "react-redux";
import cn from "classnames";

import { DeleteEmail } from "src/feature/DeliveryDetails/model";
import { emailsList, removeUserEmail, setPrimaryEmail } from "src/feature/DeliveryDetails";

import { AdminCloseIcon } from "src/shared/assets/icons/kit/AdminClose";
import { Button } from "src/shared/ui";
import { Modal } from "src/shared/ui/Modal";

import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/kit/trash.svg';
import { ReactComponent as DeleteEmails } from 'src/shared/assets/images/delete-email.svg';
import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/kit/delete.svg';

import { useAppDispatch } from "src/shared/store/store";

export const EmailItem = ({checkbox, classNames}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [deleteIdValue, setDeleteIdValue] = useState<number | null>();
  const { id, isPrimary, email } = checkbox;
  const emailsArray = useSelector(emailsList);
  const dispatch = useAppDispatch();

  const onClickDelete = (id) => {
    setOpenPopup(true);
    setDeleteIdValue(id);
  };

  const onClickDeleteItem = async (value) => {
    const item = emailsArray?.find(item => item.id === value);
    if (item) {
      dispatch(removeUserEmail({emailId: item.id}));
      dispatch(DeleteEmail(item.id));
    }

    setOpenPopup(false);
  };

  const onCloseModal = () => {
    setOpenPopup(false);
  };

  const setPrimaryEmailChange = (value) => {
    if (emailsArray) {
      const foundEmails = emailsArray.find(item => item?.id === value)

      if (foundEmails) {
        dispatch(setPrimaryEmail({emailId: foundEmails.id}));
      }
    }
  };

  return (
    <>
      <style>{`
        input[type='radio'] + div  {
          border: 2px solid rgb(255, 255, 255); 
        }

        input[type='radio']:checked + div  {
          border: 4px solid #FF335F; 
        }
      `}</style>

      <div className={cn("flex justify-between xl:w-[445px] items-center", classNames)}>
        <label
          className={'flex w-full'}
          onClick={() => {
            setPrimaryEmailChange(id)
          }}
        >
          <input
            id={id}
            type='radio'
            value={email}
            checked={isPrimary}
            className='hidden'
          />
          <div
            className={cn(
              'flex h-6 w-6 items-center justify-center rounded-full border mr-3',
            )}
          />
          {email}
        </label>

        <div
          onClick={() => onClickDelete(id)}
        >
          <TrashIcon className="h-4 w-4 fill-white cursor-pointer transition-all group-hover:fill-primary-100 max-d-xs:h-5 max-d-xs:w-5 ml-2" />
        </div>
      </div>

      <Modal
        isOpen={openPopup}
        onClose={onCloseModal}
      >
        <div className='fixed inset-0 z-2000 flex h-[100%] w-[100%] items-center justify-center bg-black/90 backdrop-blur-sm px-4'>
          <div className='bg-[#262626] max-w-[700px] flex self-center w-full rounded-[30px] p-6 xl:pt-[50px] xl:px-[50px] xl:pb-[60] flex-col'>
            <div className='font-default-medium flex justify-end w-full mb-6 text-[18px] xl:text-[20px] xl:mb-[40px] items-center'>
              <span
                className='w-[18px] h-[18px]'
                onClick={onCloseModal}
              >
                <AdminCloseIcon />
              </span>
            </div>
            <div className='flex justify-center flex-col items-center'>
              <DeleteEmails />
              <div className='my-8 font-default-medium text-center md:flex md:flex-col'>
                <span>Вы уверены, что хотите удалить почтовый ящик?</span>
                <span> Дальнейшее восстановление будет невозможно</span>
              </div>
            </div>

            <div className="buttons-wrapper flex flex-col md:flex-row w-full md:justify-center">
              <Button
                className="w-full justify-center xl:block h-11 md:max-w-[235px] self-end md:self-center mb-4 md:mb-0"
                variant={'primary'}
                size="lg"
                onClick={onCloseModal}
                disabled={false}
              >Отмена</Button>

              <Button
                className="w-full justify-center h-11 md:max-w-[235px] self-end md:self-center md:ml-5"
                variant={'blackOutline'}
                size="lg"
                onClick={() => onClickDeleteItem(deleteIdValue)}
              >
                <DeleteIcon className="h-6 w-6 fill-primary-100 transition-all mr-[11px] group-hover:fill-primary-100 max-d-xs:h-5 max-d-xs:w-5 ml-2" />
                Удалить
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
