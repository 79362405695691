import React, {FC, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentIndex, selectSliderCards } from "src/feature/SpecialOffer/model/getters";
import { Link } from "react-router-dom";
import { useSliderControls } from "src/feature/SpecialOffer/lib/useSliderControls";
import { useAppDispatch } from "src/shared/store/store";
import { fetchSliderSpecialOfferList } from "src/feature/SpecialOffer/model/action";
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { ReactComponent as LogoGame } from 'src/shared/assets/icons/kit/logoGame.svg';
import cn from "classnames";

export const SpecialOffer: FC = () => {
  const cards = useSelector(selectSliderCards);
  const currentIndex = useSelector(selectCurrentIndex);
  const { handleNext, handlePrev } = useSliderControls();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSliderSpecialOfferList());
  }, [dispatch]);

  const getVisibleCardsCount = () => {
    if (typeof window === 'undefined') return 4;
    if (window.innerWidth < 768) return 1;
    if (window.innerWidth < 1200) return 2;
    return 4;
  };

  const visibleCardsCount = getVisibleCardsCount();
  const visibleCards = Array.from({ length: visibleCardsCount }, (_, i) =>
    cards[(currentIndex + i) % cards.length]
  );

  if (cards.length === 0) return null;

  return (
    <>
      <style>
        {
          `
            .gameLink {              
              box-shadow:
                0px 0px 30px 0px rgba(255, 51, 95, 0.6),
                0px 0px 7px 0px rgba(255, 51, 95, 0.8);
            }
          `
        }
      </style>

      <div className="relative mb-[66px] d-xl:mb-[156px]">
        <div className="container max-w-[1720px] mx-auto px-4 d-xl:px-0">
          <div className="flex flex-col">
            <div className="flex justify-between mb-5 d-xs:mb-[42px]">
              <h2 className="font-default-semibold text-[24px] d-xl:text-[32px] leading-[32px]">Играй бесплатно</h2>

              <div>
                <div className="flex justify-between items-center gap-3">
                  <button
                    onClick={handlePrev}
                    className={cn(
                      'bg-bgPrimary text-white',
                      'aspect-square h-[30px] w-[30px] rounded-[50%]'
                    )}
                    aria-label="Предыдущая страница"
                  >
                    <ArrowSmallIcon className="rotate-0 h-3 stroke-[1.5]"/>
                  </button>

                  <button
                    onClick={handleNext}
                    className={cn('bg-bgPrimary text-white',
                      'aspect-square h-[30px] w-[30px] rounded-[50%]',
                    )}
                    aria-label="Следующая страница"
                  >
                    <ArrowSmallIcon className="rotate-180 h-3 stroke-[1.5]"/>
                  </button>
                </div>
              </div>
            </div>

            <div className="relative">
              <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4`}>
                {visibleCards.map((card: any) => (
                  <Link
                    key={card.id || card.title}
                    to={card.link}
                    className="flex flex-col rounded-lg"
                  >
                    <div className="relative group rounded-[20px] mb-[22px] d-xl:mb-[17px]">
                      <img
                        src={card.image}
                        alt={card.title}
                        className="w-full h-[351px] d-xl:h-[437px] max-w-[407px] object-cover rounded-[20px] mx-auto"
                      />

                      <div className="hidden d-xs:flex  absolute top-0 left-0 right-0 bottom-0 opacity-0 group-hover:opacity-100
                      transition-opacity duration-300
                      pt-[160px] justify-center
                      bg-black bg-opacity-50">
                        <div className="mx-auto flex gameLink bg-black py-[24px] size-[20px] leading-[100%] font-[900] italic items-center max-w-[205px] w-full justify-center rounded-full uppercase" >
                          <LogoGame className="mr-[15px] h-[50px] my-[-3px]" /> игра<span className="text-[rgba(255,51,95,1)]">ть</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="text-[24px] leading-[24px] font-default-medium text-center d-xl:text-left">{card.title}</h3>

                    <div className="mx-auto mt-[24px] flex d-xs:hidden gameLink bg-black py-[24px] size-[20px] leading-[100%] font-[900] italic items-center max-w-[360px] w-full justify-center rounded-full uppercase" >
                      <LogoGame className="mr-[15px] h-[50px] my-[-3px]" /> игра<span className="text-[rgba(255,51,95,1)]">ть</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};