import React, {FC} from 'react';
import cn from "classnames";
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';

interface TagProps {
  value: string;
  classNames?: string;
}

export const Tag: FC<TagProps> = ({value, classNames}) => {
  const { isMobile } = useIsMobile();

  const getTagStatus = (key) => {
    const colorMap = {
      'discount': 'Скидка',
      'exclusive': 'Эксклюзивный товар',
    };

    return colorMap[key];
  };

  return (
    <div className={cn(
      `flex font-default-regular mx-[-1px] w-fit -skew-x-12 rounded items-center h-[29px] d-xs:h-[36px] text-[12px] leading-[14px] xl:text-[16px] xl:leading-[14px] px-5 py-[6px] text-nowrap`,
      {
        'bg-[#ff335f]': value === 'discount',
        'bg-[#FF8833]': value === 'exclusive',
      })}>
      {value === 'discount' && (
        isMobile
          ? <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-[5px]'>
            <path d="M4.875 1.16679C4.875 0.433452 5.733 0.0576184 6.24975 0.565118C7.84062 2.12845 8.76525 5.56095 7.67162 8.16512L7.60662 8.31012L7.61637 8.31262C8.12419 8.42345 8.59381 7.95429 9.48756 6.50179L9.60131 6.31512C9.66724 6.20628 9.75674 6.1145 9.86288 6.04691C9.96901 5.97931 10.0889 5.93774 10.2133 5.9254C10.3376 5.91307 10.4631 5.93031 10.5799 5.97579C10.6967 6.02126 10.8016 6.09375 10.8867 6.18762C11.9706 7.38345 13 9.83512 13 11.4126C13 14.9668 10.0823 17.8335 6.5 17.8335C2.91769 17.8335 0 14.9668 0 11.4118C0 9.53512 0.830375 7.48178 2.1385 6.16095L2.63006 5.67012C2.82587 5.47345 2.98269 5.31179 3.13219 5.15012C4.29406 3.89012 4.875 2.71345 4.875 1.16679Z" fill="white"/>
          </svg>
          : <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-[14px]'>
            <path d="M5.99805 0.999751C5.99805 0.119751 7.05405 -0.331249 7.69005 0.277752C9.64805 2.15375 10.786 6.27275 9.44005 9.39775L9.36005 9.57175L9.37205 9.57475C9.99705 9.70775 10.575 9.14475 11.675 7.40175L11.815 7.17775C11.8962 7.04714 12.0063 6.937 12.137 6.8559C12.2676 6.77479 12.4152 6.72489 12.5682 6.71009C12.7213 6.69529 12.8757 6.71598 13.0194 6.77055C13.1632 6.82513 13.2924 6.91211 13.397 7.02475C14.731 8.45975 15.998 11.4018 15.998 13.2948C15.998 17.5598 12.407 20.9998 7.99805 20.9998C3.58905 20.9998 -0.00195312 17.5597 -0.00195312 13.2938C-0.00195312 11.0418 1.02005 8.57775 2.63005 6.99275L3.23505 6.40375C3.47605 6.16775 3.66905 5.97375 3.85305 5.77975C5.28305 4.26775 5.99805 2.85575 5.99805 0.999751Z" fill="white"/>
          </svg>
        )}
      <div className="text-[12px] leading-[14px] xl:text-[16px] xl:leading-[14px] text-nowrap font-default-regular skew-x-0 not-italic">{getTagStatus(value)}</div>
    </div>
  );
};

