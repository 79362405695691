import { FC } from 'react';
import cn from 'classnames';
import { ProductType } from 'src/shared/api/catalog';
import { ReactComponent as MinusIcon } from 'src/shared/assets/icons/kit/minus.svg';
import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/kit/plus.svg';
import { useAddToBasket } from 'src/shared/lib/hooks';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

interface ProductAddButtonProps {
  product: ProductType;
  className?: string;
  marginTopAmount?: string;
}

export const ProductAddButton: FC<ProductAddButtonProps> = ({
  product,
  className,
  marginTopAmount,
}) => {
  const { addToBasket, removeOneFromBasket, isLoading, amount } = useAddToBasket({ product });

  return amount ? (
    <div
      className={cn(
        'flex h-10 w-full items-center rounded-full border border-gray-400/60 bg-black/20 ',
        'shadow-inner shadow-black/70 transition-all hover:bg-black/30',
        `mt-${marginTopAmount}`,
        className,
      )}
      style={{ width: 'fit-content !important' }}
    >
      <button
        className="flex h-full w-full items-center justify-center border-r border-gray-400/60"
        onClick={removeOneFromBasket}
      >
        <MinusIcon className="h-4 w-4 fill-white/80" />
      </button>
      <div className="flex w-full min-w-10 items-center justify-center text-white">
        {isLoading ? (
          <Loader classNames="w-5 h-5 text-white/80" />
        ) : (
          <div className="font-default-medium text-center">{amount}</div>
        )}
      </div>
      <button
        className="flex h-full w-full items-center justify-center border-l border-gray-400/60"
        onClick={addToBasket}
      >
        <PlusIcon className="h-4 w-4 fill-white/80" />
      </button>
    </div>
  ) : (
    <Button
      variant="primary"
      className={className ? className : 'w-full flex justify-center'}
      onClick={addToBasket}
    >
      {isLoading ? <Loader classNames="w-5 h-5" /> : 'Купить'}
    </Button>
  );
};
