import { CSSProperties, FC, useState } from 'react';
import cn from 'classnames';
import { Img } from 'src/shared/ui';

interface CardImgCarouselProps {
  images: string[];
  styles?: CSSProperties;
}

export const CardImgCarousel: FC<CardImgCarouselProps> = ({ images, styles }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const imagesList = images.slice(0, 4);

  return (
    <div className="group relative h-[200px] d-xs:h-[284px] h-full w-full overflow-hidden rounded-[4px]" style={styles || { height: '100%', width: '100%' }}>
      {imagesList.map((img, i) => (
        <div
          key={`img-${i}`}
          className={cn({ hidden: i !== currentImage }, ' inset-0 w-full')}
          style={styles || { height: '100%', width: '100%' }}
        >
          <Img src={img} classNames="object-cover h-full" />
        </div>
      ))}

      <div className="absolute inset-0 z-20 flex h-full w-full flex-nowrap">
        {imagesList.map((_, i) => (
          <div
            key={`img-selector-${i}`}
            onMouseEnter={() => setCurrentImage(i)}
            onMouseLeave={() => setCurrentImage(0)}
            className={cn(
              {
                'group-hover:border-grey-400': i !== currentImage,
                'group-hover:border-black-100': i === currentImage,
              },
              'h-full w-full border-b-2 border-transparent',
            )}
          />
        ))}
      </div>
    </div>
  );
};
