import { FC, useCallback, useContext, useEffect, useMemo, useState, memo } from 'react';
import cn from 'classnames';
import { Container } from 'src/entity/Container';
import { BannersList } from 'src/feature/BannersList';
import { GridFilter } from 'src/feature/GridFilter';
import { ProductGridCard } from 'src/feature/ProductGridCard';
import useBanners from 'src/hooks/useBanners';
import { ProductType } from 'src/shared/api/catalog';
import { sort } from 'src/shared/const';
import { Loader } from 'src/shared/ui/Loader';
import { CatalogHead } from 'src/widgets/CatalogHead';
import { ReactComponent as LoupeIcon } from 'src/shared/assets/icons/kit/loupe.svg';

import { Button } from 'src/shared/ui';
import { CatalogContext } from 'src/shared/store';
import { SearchNotFound } from 'src/shared/assets/icons/kit/SearchNotFound';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';
import { useCatalog } from './hooks/useCatalog';
import { usePreviousPath } from 'src/hooks/usePreviousPath';

interface CatalogPageProps {
  isFavorite?: boolean;
  isSearch?: boolean;
  isCatalog?: boolean;
}

const MemoProductGridCard = memo(ProductGridCard);

export const CatalogPage: FC<CatalogPageProps> = ({ isFavorite, isSearch, isCatalog }) => {
  const { banners } = useBanners(4);
  const { filters, setFilters } = useContext(CatalogContext);
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const [selectedSort, setSelectedSort] = useState(sort[0]);
  const [search, setSearch] = useState(filters.search);
  const [isBought, setIsBought] = useState<boolean>(window.location.search.includes('bought'));
  const prevUrl = usePreviousPath();

  const { isLoading, products, setProducts } = useCatalog({
    selectedSort,
    isBought,
    isFavorite,
    prevPathname: prevUrl,
  });

  const onFavorite = useCallback(
    (product: ProductType) => {
      setProducts(prev =>
        prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
      );
    },
    [setProducts],
  );

  const onSearchChange = useCallback((content: string) => {
    setSearch(content);
  }, []);

  const onSearchSubmit = useCallback(() => {
    setFilters(prev => ({ ...prev, search, isAllLoading: false }));
  }, [search, setFilters]);

  const productList = useMemo(
    () =>
      products.map(el => (
        <div className="t-lg:px-[10px] t-lg:pt-[10px] t-lg:pb-[20px] t-lg:bg-[#1e1e1e] t-lg:rounded-[4px]" key={el.id}>
          <MemoProductGridCard
            onFavorite={onFavorite}
            data={el}
            cardStyles={{ height: '100%', width: '100%' }}
          />
        </div>
      )),
    [products, onFavorite],
  );

  const renderProductGrid = useMemo(
    () => (
      <div
        className="grid grid-cols-2 t-lg:grid-cols-3 d-xs:grid-cols-4 d-xl:grid-cols-5 gap-4 d-xl:gap-[30px]"
      >
        {productList}
      </div>
    ),
    [productList, isFavorite],
  );

  const memoizedBanners = useMemo(
    () => (
      <div className={isCatalog ? 'mx-4' : ''}>
        <div className="aspect-banner-catalog w-full overflow-hidden">
          <BannersList list={banners} />
        </div>
      </div>
    ),
    [banners, isCatalog],
  );

  useEffect(() => {
    setSearch(filters.search);
  }, [filters.search]);

  const shouldShowNotFound = !isLoading && !products.length;
  const shouldShowLoader = isLoading;
  const isShowBanner = banners?.length && !isFavorite && !isSearch;
  const shouldShowProducts = !isLoading && products.length;

  return (
    <Container offsetX={false}>
      <div className="flex flex-1 pt-4 d-xs:pt-14 d-xs:px-4 d-xl:px-0">
        <div className="hidden h-full w-71 flex-shrink-0 d-xs:block">
          <div className={`sticky top-20 ${isShowBanner ? 'pt-[54px]' : 'pt-7'}`}>
            <div className="flex flex-col">
              <GridFilter />
            </div>
          </div>
        </div>

        <div className={`flex flex-1 flex-col d-xs:ml-70 d-xs:ml-2 ${isFavorite ? 'pt-[20px]' : 'pt-[52px]' } ${(isShowBanner && !isCatalog) || (isSearch && isMobile) ? 'mx-4' : ''}`}>
          {!!isShowBanner && <div className="h-[300px] d-xs:h-[200px] mb-6 d-xs:mt-0 d-xs:mb-[30px]">{memoizedBanners}</div>}

          {isSearch && (
            <div className="relative mb-[50px] box-border flex h-14 w-full shrink-0 grow-0 basis-auto flex-row items-center justify-start gap-5 self-stretch rounded-[30px] border border-solid border-[rgba(217,218,218,0.60)] bg-neutral-800 py-[30px] pl-5 shadow-[inset_0px_5px_10px_rgba(0,0,0,0.70)]">
              {!isMobile && (
                <LoupeIcon className="box-border flex h-[21px] w-[21px] shrink-0 grow-0 basis-auto text-white" />
              )}
              <input
                value={search}
                onChange={e => onSearchChange(e.target.value)}
                className="m-0 box-border shrink-0 grow-0 basis-auto p-0 text-left font-normal leading-4 text-[white]"
              />
              <Button
                variant="primary"
                onClick={onSearchSubmit}
                className={`absolute right-2 font-medium leading-5 text-[white] cursor-pointer h-11 block box-border grow-0 shrink-0 basis-auto rounded-[30px] border-[none] ${isMobile ? 'w-[50px]' : 'min-w-[185px]'}`}
              >
                {isMobile ? <LoupeIcon /> : 'Искать'}
              </Button>
            </div>
          )}

          {isFavorite && (
            <div className="px-4 d-xs:pl-[30px] flex flex-col pt-[26px] d-xs:pt-[0]">
              <div className="flex items-center mb-6">
                <div className={`font-default-regular text-[24px] d-xs:text-[36px]`}>
                  Избранное
                </div>
              </div>
              {!isMobile && (
                <div className="mb-[40px] flex gap-3">
                  <Button
                    onClick={() => setIsBought(false)}
                    className="rounded-lg font-default-regular"
                    variant={isBought ? 'darkPrimary' : 'primary'}
                    size="xsm"
                  >
                    Сохранили
                  </Button>
                  <Button
                    className="rounded-lg"
                    variant={isBought ? 'primary' : 'darkPrimary'}
                    size="xsm"
                    onClick={() => setIsBought(true)}
                  >
                    Покупали
                  </Button>
                </div>
              )}
            </div>
          )}

          {(!isFavorite || (isFavorite && isMobile) || (isCatalog && isMobile)) && (
            <CatalogHead
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              isSearch={isSearch}
              isFavorite={isFavorite}
              isCatalog={isCatalog}
            />
          )}

          {isFavorite && isMobile && (
            <div className="py-4 px-4 flex gap-3">
              <Button
                onClick={() => setIsBought(false)}
                className="rounded-lg font-default-regular"
                variant={isBought ? 'darkPrimary' : 'primary'}
                size="xsm"
              >
                Сохранили
              </Button>
              <Button
                className="rounded-lg"
                variant={isBought ? 'primary' : 'darkPrimary'}
                size="xsm"
                onClick={() => setIsBought(true)}
              >
                Покупали
              </Button>
            </div>
          )}

          <div className="flex-1 px-4 d-xs:pl-6 d-xs:pr-0 pb-[110px]">
            {!!shouldShowNotFound && (
              <div className="width-full mt-[120px] flex flex-col items-center gap-[30px]">
                <SearchNotFound />
                <div className="font-default-medium mt-30 text-center text-[24px]">
                  По вашему запросу ничего не найдено
                </div>
                <Button variant="primary" className="w-[325px]" onClick={() => navigate('/')}>
                  На главную
                </Button>
              </div>
            )}

            {!!shouldShowLoader && (
              <Loader classNames="flex justify-center w-full mx-auto mt-40 w-20 h-20 text-primary-100" />
            )}

            {!!shouldShowProducts && renderProductGrid}
          </div>
        </div>
      </div>
    </Container>
  );
};
