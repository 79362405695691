import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {SlidersList} from "src/feature/PromoSlider/model/getters";
import {fetchPromoSlider} from "src/feature/PromoSlider/model/action";
import {useAppDispatch} from "src/shared/store/store";
import {Tag} from "src/shared/ui/Tag";
import { ReactComponent as HeartIcon } from 'src/shared/assets/icons/kit/heart.svg';
import { ReactComponent as IconStar } from 'src/shared/assets/icons/kit/iconStar.svg';
import {Button, DiscountPercent, DiscountPrice} from "src/shared/ui";
import cn from "classnames";
import {addSpaces} from "src/shared/lib/utils";

export const PromoSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slidersList = useSelector(SlidersList);
  const dispatch = useAppDispatch();

  useEffect( () => {
    dispatch(fetchPromoSlider())
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slidersList.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slidersList.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <>
      <style>
        {
          `.params:first-of-type:before {
            display: none;
          }
          .scrollbar-hide {
                -ms-overflow-style: none;
                scrollbar-width: none;
            }

            .scrollbar-hide::-webkit-scrollbar {
                display: none;
            }
          `
        }
      </style>
      <div className="relative w-full h-[806px] xl:h-[1000px] mx-auto group d-xs:mb-[60px] d-xl:mb-[120px]">
        <div className="relative  h-[806px] xl:h-[1000px] overflow-hidden">
          {slidersList.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ${
                slideIndex === currentIndex ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full h-full object-cover"
              />
              <div className="flex  flex-col justify-end absolute bottom-0 top-0 left-0 right-0 bg-gradient-to-b from-[rgba(24,24,24,0)] via-[rgba(24,24,24,0.8)_60.07%] to-[#181818_90.04%] px-4 pb-[40px]">
                <div className='max-w-[1720px] w-full mx-auto'>
                  <div className='flex mb-[10] xl:mb-[24] gap-[18px] md:gap-[20px] ml-1'>
                    {slide.tags.map(item => <Tag value={item} />)}
                  </div>
                  <h3 className="text-white font-default-bold tracking-[0.2px] text-[22px] leading-[22px] xl:text-[32px] xl:leading-[34px] mt-[14px] d-xs:mt-7">{slide.title}</h3>
                  <p className="text-white font-default-regular text-[12px] xl:text-[16px] max-w-[520px] leading-[16px] d-xs:leading-[20px] mt-[9px] d-xs:mt-5">{slide.description}</p>
                  {slide.params &&
                    <div className="flex items-center mt-[18px] xl:mt-7">
                      <IconStar className="mr-2 xl:mr-5 w-5 h-5 md:w-9 md:h-9" />
                      {slide.params.map(item => <div className="flex params font-default-medium items-center text-[#a6a6a6] text-[12px] leading-[12px] xl:text-[18px] mr-[4px] xl:mr-5 before:content-[''] before:block before:w-[6px] before:h-[6px] before:bg-[#a6a6a6] before:mr-[5px] xl:before:mr-5 first:before:hidden"  key={item}>{item}</div>)}
                    </div>
                  }

                  <div className="flex items-center gap-[16px] mt-5 d-xs:mt-7">
                    <div className="text-[30px] leading-[32px] xl:text-[40px] xl:leading-[40px] font-default-medium">{`${addSpaces(+slide.price.newPrice.replace(',', '.'))} ₽`}</div>

                    {slide.price.discount.toString() !== '0' && slide.price.discount && (
                      <DiscountPrice price={slide.price.oldPrice} classNames="decoration-[#404040] font-default-regular text-[#c4c4c4] text-[14px] leading-[14px]" />
                    )}

                    {slide.price.discount.toString() !== '0' && slide.price.discount && (
                      <DiscountPercent percent={slide.price.discount} big className='px-[4px] font-default-medium' />
                    )}

                    <div className="flex justify-center items-center p-[6px] rounded-full bg-black italic">
                      <div className="w-[18px] h-[18px] md:h-[28px] md:w-[28px] flex justify-center items-center rounded-full border-2 text-[8px] border-white">i</div>
                    </div>
                  </div>

                  <div className='flex gap-4 mt-5 md:mt-[22px]'>
                    <Button
                      variant="primary"
                      size={'sl'}
                      className={'flex justify-center px-[32px] xl:w-[175px] font-default-medium'}
                      onClick={() => {}}
                    >Купить</Button>
                    <Button
                      variant="grayPrimary"
                      size={'sl'}
                      className={'flex justify-center px-[22px] xl:w-[210px] font-default-medium'}
                      onClick={() => {}}
                    >Подробнее</Button>
                    <div className={'w-[44px] h-[44px] bg-[#000000] rounded-full flex items-center justify-center'}>
                      <HeartIcon
                        className={cn(
                          'fill-white w-[24px] h-[22px]',
                          {
                            'fill-primary-300': slide.onFavorite === true
                          }
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className='absolute top-0 left-0 w-full'>
            {/*<div className=' w-full mx-auto items-end hidden'>*/}
            {/*  <button*/}
            {/*    onClick={goToPrevious}*/}
            {/*    className="invisible xl:visible bg-black/30 text-white p-2 rounded-full mr-5"*/}
            {/*  >*/}
            {/*    <svg*/}
            {/*      xmlns="http://www.w3.org/2000/svg"*/}
            {/*      className="h-6 w-6"*/}
            {/*      fill="none"*/}
            {/*      viewBox="0 0 24 24"*/}
            {/*      stroke="currentColor"*/}
            {/*    >*/}
            {/*      <path*/}
            {/*        strokeLinecap="round"*/}
            {/*        strokeLinejoin="round"*/}
            {/*        strokeWidth={2}*/}
            {/*        d="M15 19l-7-7 7-7"*/}
            {/*      />*/}
            {/*    </svg>*/}
            {/*  </button>*/}
            {/*  <button*/}
            {/*    onClick={goToNext}*/}
            {/*    className="invisible xl:visible bg-black/30 text-white p-2 rounded-full "*/}
            {/*  >*/}
            {/*    <svg*/}
            {/*      xmlns="http://www.w3.org/2000/svg"*/}
            {/*      className="h-6 w-6"*/}
            {/*      fill="none"*/}
            {/*      viewBox="0 0 24 24"*/}
            {/*      stroke="currentColor"*/}
            {/*    >*/}
            {/*      <path*/}
            {/*        strokeLinecap="round"*/}
            {/*        strokeLinejoin="round"*/}
            {/*        strokeWidth={2}*/}
            {/*        d="M9 5l7 7-7 7"*/}
            {/*      />*/}
            {/*    </svg>*/}
            {/*  </button>*/}
            {/*</div>*/}

            <div className="flex relative mt-[394px] t-lg:mt-[300px] d-xs:mt-[320px] justify-center w-full scrollbar-hide items-center overflow-x-auto space-x-2 py-2 h-[76px] t-lg:h-[130px] xl:h-[240px]">
              {slidersList.map((slide, slideIndex) => (
                <div
                  key={slideIndex}
                  onClick={() => goToSlide(slideIndex)}
                  className={`flex-shrink-0 rounded-[5px] xl:rounded-[12px] overflow-hidden transition-all duration-300 ${
                    slideIndex === currentIndex
                      ? 'w-[69px] h-[69px] t-lg:w-[120px] t-lg:h-[120px] xl:w-[240px] xl:h-[240px] self-center border-solid border-2 bg-transparent p-[3px] xl:p-[5px] border-[#ff335f]'
                      : 'opacity-70 hover:opacity-100 w-[50px] h-[50px] t-lg:w-[75px] t-lg:h-[75px] xl:w-[100px] xl:h-[100px]'
                  }`}
                >
                  <img
                    src={slide.image}
                    alt={`Thumbnail ${slideIndex}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};