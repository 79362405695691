import { FC, useContext, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { CatalogContext } from 'src/app/providers/CatalogProvider';
import { GridFilter } from 'src/feature/GridFilter';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/kit/check.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as FilterIcon } from 'src/shared/assets/icons/kit/filter.svg';
import { ReactComponent as GridIcon } from 'src/shared/assets/icons/kit/grid.svg';
import { ReactComponent as ListIcon } from 'src/shared/assets/icons/kit/list.svg';
import { ReactComponent as SortIcon } from 'src/shared/assets/icons/kit/sort.svg';
import { sort } from 'src/shared/const';
import { useClickOutside } from 'src/shared/lib/hooks';
import { SortType, StateActionType } from 'src/shared/types';
import { Button, Select } from 'src/shared/ui';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';
import { MenuIcon } from 'src/shared/assets/icons/kit/Menu';

interface CatalogHeadProps {
  isGrid?: boolean;
  setIsGrid?: StateActionType<boolean>;
  selectedSort: SortType;
  setSelectedSort: StateActionType<SortType>;
  isSearch?: boolean;
  isCatalog?: boolean;
  isFavorite?: boolean;
}

export const CatalogHead: FC<CatalogHeadProps> = ({
  isGrid,
  setIsGrid,
  setSelectedSort,
  selectedSort,
  isSearch,
  isCatalog,
  isFavorite,
}) => {
  const { filters, initialFilters, resetFilters } = useContext(CatalogContext);
  const { isMobile } = useIsMobile();

  const sortRef = useRef<HTMLDivElement>(null);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useClickOutside(sortRef, () => setIsSortOpen(false));

  const isFilterActive = useMemo(() => {
    if (!initialFilters) {
      return false;
    }

    let res = false;
    Object.keys(filters.parameters).forEach(key => {
      if (filters.parameters?.[key]?.length) {
        res = true;
      }
    });

    return (
      res ||
      filters.price.min !== +(initialFilters?.priceMin || 0) ||
      filters.price.max !== +(initialFilters?.priceMax || 0)
    );
  }, [filters, initialFilters]);

  return (
    <div
      className={`flex w-full d-xs:py-[4px] d-xs:pl-6 d-xs:mb-6 items-center ${isSearch || isFavorite || isCatalog ? 'justify-end' : 'border-bgPrimary'}`}
    >
      {isMobile ? (
        <div className="flex w-[100%] flex-col gap-[16px]">
          <button
            className="flex w-full items-center justify-center gap-2 border-b border-t border-[#616161] py-4 text-textPrimary d-xs:hidden"
            onClick={() => setIsFilterOpen(true)}
          >
            <MenuIcon />
            <div className="font-default-regular text-[14px]">Показать фильтры</div>
          </button>

          {!isFavorite && (
            <>
              {!isCatalog && (
                <div className={`${isMobile ? 'w-[100%]' : 'w-64'}`}>
                  <Select options={sort} onChange={setSelectedSort} value={selectedSort} />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="flex">
          <div className="w-[248px]">
            <Select options={sort} onChange={setSelectedSort} value={selectedSort} />
          </div>
          <button
            className="ml-auto flex items-center text-textPrimary d-xs:hidden"
            onClick={() => setIsFilterOpen(true)}
          >
            <FilterIcon className="ml-2 h-5 w-5" />
            <div className="font-default-medium">Фильтры</div>
          </button>
        </div>
      )}
      {/* <button
        className="flex items-center text-textPrimary d-xs:hidden"
        onClick={() => setIsSortOpen(true)}
      >
        <SortIcon className="mr-2 h-5 w-5 rotate-180" />
        <div className="font-default-medium text">{selectedSort.label}</div>
      </button> */}

      {setIsGrid && (
        <div className="ml-auto hidden gap-2 d-xs:flex">
          <GridIcon
            onClick={() => setIsGrid(true)}
            className={cn(
              { 'fill-grey-500': isGrid, 'fill-grey-600 hover:fill-grey-500': !isGrid },
              'h-5 w-5 cursor-pointer',
            )}
          />
          <ListIcon
            onClick={() => setIsGrid(false)}
            className={cn(
              { 'fill-grey-500': !isGrid, 'fill-grey-600 hover:fill-grey-500': isGrid },
              'h-5 w-5 cursor-pointer',
            )}
          />
        </div>
      )}

      {isSortOpen &&
        createPortal(
          <div className="fixed inset-0 z-2000 flex h-full w-full bg-bgPrimary bg-opacity-20">
            <div
              className="mt-auto w-full rounded-t-3xl bg-bgPrimary py-8 text-textLighter"
              ref={sortRef}
            >
              {sort.map(option => (
                <div
                  className="flex w-full cursor-pointer items-center px-4 py-3"
                  onClick={() => {
                    setSelectedSort && setSelectedSort(option);
                    setIsSortOpen(false);
                  }}
                  key={option.value}
                >
                  <div
                    className={cn(
                      { 'text-primary-100': selectedSort?.value === option.value },
                      'font-default-medium',
                    )}
                  >
                    {option.label}
                  </div>

                  {selectedSort?.value === option.value && (
                    <CheckIcon className="ml-auto h-5 w-5 stroke-primary-100" />
                  )}
                </div>
              ))}
            </div>
          </div>,
          document.body,
        )}

      {isFilterOpen &&
        createPortal(
          <div className="fixed inset-0 z-2000 flex h-full w-full flex-col bg-body text-textPrimary">
            <div className="flex flex-shrink-0 items-center justify-between border-b border-bgHover py-4">
              <div className="flex-1 pl-5">
                <button onClick={() => setIsFilterOpen(false)}>
                  <CloseIcon className="h-3 w-3 text-textLighter" />
                </button>
              </div>

              <div className="font-default-medium flex-1 text-center">Фильтры</div>

              <div className="flex flex-1 flex-row justify-end pr-5">
                <button
                  className={cn(
                    isFilterActive ? 'text-primary-100' : 'text-textDisabled',
                    'rounded-full text-sm',
                  )}
                  onClick={resetFilters}
                >
                  Очистить
                </button>
              </div>
            </div>

            <div className="flex-1 overflow-auto p-4">
              <GridFilter />
            </div>

            <div className="w-full flex-shrink-0 p-2.5" onClick={() => setIsFilterOpen(false)}>
              <Button variant="primary" size="sm" className="w-full">
                Показать товары
              </Button>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};
