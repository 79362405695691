import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "src/shared/store/store";
import { ArticleCard } from "src/feature/ArticlesBlock/ui/ArticleCard";
import { Button } from "src/shared/ui";
import { nextSlide, prevSlide} from "src/feature/ArticlesBlock/model";
import { fetchSliderArticleList } from "src/feature/ArticlesBlock/model/action";
import cn from "classnames";
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';

export const ArticleSlider: React.FC = () => {
  const dispatch = useAppDispatch();
  const { articles, currentIndex } = useAppSelector(state => state.articleContainer);

  const visibleArticles = articles.slice(currentIndex, currentIndex + 4);

  useEffect(() => {
    dispatch(fetchSliderArticleList());
  }, [dispatch]);

  return (
    <div className="relative">
      <div className="flex justify-between mb-[30px]">
        <h2 className="font-default-semibold text-[24px] d-xl:text-[32px] leading-[32px]">Статьи</h2>
        {articles.length > 4 && (
          <div className="flex justify-center space-x-4">
            <div className="hidden md:flex justify-between items-center gap-3">
              <button
                onClick={() => dispatch(prevSlide())}
                className={cn(
                  'bg-bgPrimary text-white',
                  'aspect-square w-[30px] h-[30px] rounded-[50%]'
                )}
                aria-label="Предыдущая страница"
              >
                <ArrowSmallIcon className="rotate-0 h-3 stroke-[1.5]"/>
              </button>

              <button
                onClick={() => dispatch(nextSlide())}
                className={cn('bg-bgPrimary text-white',
                  'aspect-square w-[30px] h-[30px] rounded-[50%]',
                )}
                aria-label="Следующая страница"
              >
                <ArrowSmallIcon className="rotate-180 h-3 stroke-[1.5]"/>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-4 gap-6">
        {visibleArticles.map(article => (
          <ArticleCard key={article.id} article={article} />
        ))}
      </div>
    </div>
  );
};