import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";

import { useAppDispatch } from "src/shared/store/store";
import { fetchAdvertisingList } from "src/feature/AdvertisementBlock/model/action";
import { AdvertisingList } from "src/feature/AdvertisementBlock/model/getters";

import { ReactComponent as IconLink } from 'src/shared/assets/icons/kit/link.svg';

import bannerRed from "src/shared/assets/images/mock/banner-red.gif";
import bannerRedMobile from "src/shared/assets/images/mock/bannerRedMobile.gif";

import bannerBlue from "src/shared/assets/images/mock/banner-blue.gif";
import bannerBlueMobile from "src/shared/assets/images/mock/banner-blue-mobile.gif";

import zero from "src/shared/assets/images/mock/0.png";
import first from "src/shared/assets/images/mock/1.png";
import two from "src/shared/assets/images/mock/2.png";
import three from "src/shared/assets/images/mock/3.png";

export const AdvertisementBlock = () => {
  const advertisingList = useSelector(AdvertisingList);
  const dispatch = useAppDispatch();

  useEffect( () => {
    dispatch(fetchAdvertisingList());
  }, []);

  const getTagStatus = (key) => {
    const tagMap = {
      'discount': 'Скидка',
      'cashback': 'Кэшбэк',
      'advantage': 'Выгода'
    };

    return tagMap[key];
  };

  const masonryStyle = (value) => {
    const masonryMap = {
      '0': 'hidden xl:flex xl:col-start-1 xl:col-end-2 xl:row-start-3 xl:row-end-4',
      '1': 'hidden xl:flex xl:col-start-2 xl:col-end-3 xl:row-start-3 xl:row-end-4',
      '2': 'col-start-1 col-end-2 row-start-2 row-end-3 xl:col-start-3 xl:col-end-4 xl:row-start-1 xl:row-end-2',
      '3': 'col-start-2 col-end-3 row-start-2 row-end-3 xl:col-start-4 xl:col-end-5 xl:row-start-1 xl:row-end-2',
      '4': 'col-start-1 col-end-3 row-start-3 row-end-4 xl:col-start-3 xl:col-end-5 xl:row-start-2 xl:row-end-4 xl:col-span-2 xl:row-span-2',
    };

    return masonryMap[value];
  }

  return (
    <>
      <style>
        {
          `
          .gif {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center
          }
          
          .gifRed {
            background-image: url(${bannerRedMobile});
            background-position-x: center;
            background-position-y: center;
          }
            
          .gif0 {
            background-image: url(${zero});
          }
          .gif1 {
            background-image: url(${first});
          }
          .gif2 {
            background-image: url(${two});
            background-position-x: center;
            background-position-y: 25%;
          }
          .gif3 {
            background-image: url(${three});
            background-position-x: center;
            background-position-y: 50%;
          }
          .gif4 {
            background-image: url(${bannerBlueMobile});
            background-position-x: center;
          }
          
          @media (min-width: 1200px) {
            .gif4 {
               background-position-y: center;
            }
          
            .gifRed {
              background-image: url(${bannerRedMobile});  
            }

            .gif4 {
              background-image: url(${bannerBlue}); 
            }
          }
          `
        }
      </style>


      <div className='
        max-w-[1720px]
        mx-auto
        mb-[40px]
        xl:my-[120px]
        grid
        gap-[4px_4px]
        auto-cols-[1fr]
        grid-cols-[1fr_1fr]
        grid-rows-[448px_240px_448px]
        xl:grid-cols-[1fr_1fr_1fr_1fr]
        d-xs:grid-rows-[406px_406px_406px]
        xl:grid-rows-[406px_147px_406px]
        d-xl:grid-rows-[406px_147px_406px]'>
        <div className='gifRed gif col-start-1 col-end-3 row-start-1 row-end-2 xl:col-start-1 xl:col-end-3 xl:row-start-1 xl:row-end-3 col-span-2 xl:row-span-2 px-4 pt-[55px] pb-[76px] flex justify-center d-xl:pt-[120px]'>
          <div className="flex flex-col justify-start text-center py-8 px-[10px] backdrop-blur-lg max-w-[480px] pt-[36px]">
            <div className="text-[32px] leading-[32px] font-default-semibold text-[#fe335f] mb-6">Приз лучшему игроку</div>
            <div className="text-[14px] leading-[18px] d-xs:text-[18px] d-xs:leading-[160%] font-default-medium text-white mb-[14px]">Побеждай на турнирах и играй бесплатно Приглашай друзей и получай двойные бонусы</div>
            <div className="text-[10px] leading-[18px] d-xs:text-[12px] d-xs:leading-[24px] font-default-regular text-white/60 mb-[10px]">Присоединяйся к онлайн турниру, зарабатывай очки и получай реальное игровое время бесплатно!</div>
            <Link to={'/'} className="flex w-full items-center self-center justify-center max-w-[306px] text-[16px] leading-[20px] font-[500] text-primary-200 px-[30px] py-[10px] rounded-full border-2 border-primary-200">
              <IconLink className="mr-[10px]"/>Пригласить друзей</Link>
          </div>
        </div>

        {advertisingList.map((item, id) =>
          <div className={cn(masonryStyle(id), `gif${id} gif`, "pt-5 pb-4 d-xs:pb-5 pl-[8px] pr-4 even:pl-4 even:pr-[6px] d-xs:even:px-5 d-xs:odd:px-5 ")}>
            <div className="h-full w-full flex flex-col justify-between">
              <div className="flex w-fit items-center bg-white p-1 pl-2 d-xs:pl-3 text-primary-200 text-[16px] d-xs:text-[18px] leading-[16px] d-xs:leading-[18px] rounded-full font-default-medium">{getTagStatus(item.type)}
                <div className={cn(
                  "text-[16px] d-xs:text-[22px] ml-1 text-white leading-[22px] d-xs:leading-[31px] px-2 d-xs:px-4 rounded-full whitespace-no-wrap", {
                  'bg-gradient-to-r from-[#8654CC] to-[#A3EAA2]': item.sberTag,
                  'bg-primary-200': !item.sberTag
                })}>{`до ${item.value} %`}</div>
              </div>
              <div className="font-[600] text-[16px] md:text-[32px] leading-[16px] md:leading-[32px] max-w-[385px]">{item.description}</div>
            </div>
          </div>
        )}
      </div>

    </>
  );
};


