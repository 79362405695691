import { createAsyncThunk } from "@reduxjs/toolkit";
import { SlidesList } from "src/feature/PromoSlider/model/index";

const data = [
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive', 'green'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://i.pinimg.com/originals/c0/ca/e9/c0cae9f2a0e0e20b25ae4956a86b6cc2.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://i.pinimg.com/originals/c0/ca/e9/c0cae9f2a0e0e20b25ae4956a86b6cc2.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://i.pinimg.com/originals/fc/4b/60/fc4b602f3418711faf4942db9b1fdb83.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://i.pinimg.com/originals/fc/4b/60/fc4b602f3418711faf4942db9b1fdb83.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://wallpapers.com/images/hd/all-games-ptdfpl9fb5dnf9tq.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://i.pinimg.com/originals/fc/4b/60/fc4b602f3418711faf4942db9b1fdb83.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://wallpapers.com/images/hd/all-games-ptdfpl9fb5dnf9tq.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://i.pinimg.com/originals/fc/4b/60/fc4b602f3418711faf4942db9b1fdb83.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://images.hdqwalls.com/download/spiderman-5k-2019-68-2560x1700.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://i.pinimg.com/originals/fc/4b/60/fc4b602f3418711faf4942db9b1fdb83.jpg',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['Astrum Games', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
];

export const fetchPromoSlider = createAsyncThunk(
  'homePage/fetchPromoSlider',
  async (_, { dispatch}) => {
    try {
      dispatch(SlidesList(data));
    } catch (e) {
      console.error("Error fetching emails:", e);
    }}
);