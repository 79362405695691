import React, { useState, useRef, useEffect } from 'react';
import { useProducts } from "src/pages/HomePage/hooks/useProducts";
import { ProductType } from "src/shared/api/catalog";
import { ProductGridCard } from "src/feature/ProductGridCard";

import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import cn from "classnames";

export const ProductsSimpleSlider = () => {
  const { products, setProducts } = useProducts();

  const onFavorite = (product: ProductType) => {
    setProducts(prev =>
      prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);

  const [slidesToShow, setSlidesToShow] = useState(7);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1536) setSlidesToShow(7);
      else if (width >= 1280) setSlidesToShow(6);
      else if (width >= 1024) setSlidesToShow(5);
      else if (width >= 768) setSlidesToShow(4);
      else if (width >= 640) setSlidesToShow(3);
      else setSlidesToShow(2);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) =>
      Math.min(prev + slidesToShow, products.length - slidesToShow)
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => Math.max(prev - slidesToShow, 0));
  };

  const canGoNext = currentSlide < products.length - slidesToShow;
  const canGoPrev = currentSlide > 0;

  const getTransform = () => {
    if (!sliderRef.current) return 'translateX(0)';
    const cardWidth = sliderRef.current.children[0]?.clientWidth || 0;
    const gap = 16;
    const offset = -currentSlide * (cardWidth + gap);
    return `translateX(${offset}px)`;
  };

  return (
    <div className="container w-full max-w-[1720px] mx-auto px-4 d-xl:px-0 pt-8 relative mb-10 d-xs:mb-[80px] d-xl:mb-[120px]">
      <div className="flex w-full justify-between items-center mb-[17px] md:mb-8">
        <h2 className="font-default-semibold text-[24px] d-xl:text-[32px] leading-[32px]">Карты пополнений</h2>
        <div className="flex justify-between items-center gap-3">
          <button
            onClick={prevSlide}
            className={cn(
              canGoPrev
                ? 'bg-[#202020] text-[#f5f5f5]'
                : 'bg-[#202020] text-[#f5f5f5]/50',
              'aspect-square h-[30px] rounded-[50%]'
            )}
            aria-label="Предыдущие товары"
          >
            <ArrowSmallIcon className="rotate-0 h-3 stroke-[1.5]"/>
          </button>

          <button
            onClick={nextSlide}
            className={cn(
              canGoNext
                ? 'bg-[#202020] text-[#f5f5f5/50]'
                : 'bg-[#202020] text-[#f5f5f5]',
              'aspect-square h-[30px] rounded-[50%]',
            )}
            aria-label="Следующие товары"
          >
            <ArrowSmallIcon className="rotate-180 h-3 stroke-[1.5]"/>
          </button>
        </div>
      </div>

      <div className="relative overflow-hidden">
        <div className="overflow-hidden">
          <div
            ref={sliderRef}
            className="flex gap-4 transition-transform duration-300 ease-in-out"
            style={{ transform: getTransform() }}
          >
            {products.map((product) => (
              <div
                className="d-xl:p-[10px] d-xl:pb-[20px] d-xl:bg-[#1E1E1E] rounded-[4px] flex-shrink-0"
                key={product.id}
                style={{ width: `calc((100% - ${(slidesToShow - 1) * 16}px) / ${slidesToShow})` }}
              >
                <ProductGridCard
                  onFavorite={onFavorite}
                  key={product.id}
                  data={product}
                  cardStyles={{ height: '100%', width: '100%' }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};