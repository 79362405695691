import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { routesEnum } from 'src/shared/const';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';

interface ProfileNavigationProps {
  onClose?: () => void;
}

export const ProfileMobileNavigation: FC<ProfileNavigationProps> = ({ onClose }) => {
  const { isMobile } = useIsMobile();

  const navigationGroups = [
    {
      title: 'Покупки',
      links: [
        {
          label: 'Заказы',
          path: isMobile ? routesEnum.PROFILE_ORDERS_MOBILE : routesEnum.PROFILE_ORDERS,
        },
        { label: 'Настройки профиля', path: routesEnum.PROFILE_CUSTOMIZATION },
      ],
    },
    {
      title: 'Обо мне',
      links: [
        {label: 'Личные данные', path: routesEnum.PERSONAL_INFORMATION},
        {label: 'Доступные почтовые адреса', path: routesEnum.AVAILABLE_EMAILS},
        {label: 'Настройки профиля', path: routesEnum.PROFILE_SETTINGS},
      ],
    },
    {
      title: 'Помощь',
      links: [
        {
          label: 'Обращения в техподдержку',
          path: routesEnum.CONTACT_SUPPORT || routesEnum.CHAT_SUPPORT,
        },
      ],
    },
  ];

  return (
    <div className="absolute left-0 top-0 pt-24 flex h-full w-full items-center justify-center px-4">
      <div className="flex h-full w-full flex-col gap-4">
        {navigationGroups.map(group => (
          <div key={group.title}>
            <h3 className="text-textSecondary font-default-regular mb-2">{group.title}</h3>
            <div className="flex flex-col gap-2.5">
              {group.links.map(link => (
                <Link
                  onClick={onClose && onClose}
                  key={link.path}
                  to={link.path}
                  className={cn(
                    'font-default-regular border-transparent text-textGray2 hover:border-primary-100 hover:text-textPrimary',
                    'border-l-3 pl-2 transition-all',
                  )}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ProfileNavigation: FC<ProfileNavigationProps> = ({ onClose }) => {
  const { pathname } = useLocation();

  const navigationGroups = [
    {
      title: 'Покупки',
      links: [
        { label: 'Заказы', path: routesEnum.PROFILE_ORDERS },
      ],
    },
    {
      title: 'Обо мне',
      links: [
        {label: 'Личные данные', path: routesEnum.PERSONAL_INFORMATION},
        {label: 'Доступные почтовые адреса', path: routesEnum.AVAILABLE_EMAILS},
        {label: 'Настройки профиля', path: routesEnum.PROFILE_SETTINGS},
      ],
    },
    {
      title: 'Помощь',
      links: [
        {
          label: 'Обращения в техподдержку',
          path: routesEnum.CONTACT_SUPPORT || routesEnum.CHAT_SUPPORT,
        },
      ],
    },
  ];

  return (
    <div className="flex w-full flex-col gap-4 d-xs:pt-[84px]">
      {navigationGroups.map(group => (
        <div key={group.title}>
          <h3 className="text-[16px] leading-[26px] mb-[10px] text-white/90">{group.title}</h3>
          <div className="flex flex-col gap-2.5">
            {group.links.map(link => (
              <Link
                onClick={onClose && onClose}
                key={link.path}
                to={link.path}
                className={cn(
                  {
                    'border-primary-100 text-textPrimary': pathname === link.path,
                    'border-transparent text-textGray2 hover:border-primary-100 hover:text-textPrimary':
                      pathname !== link.path,
                  },
                  'border-l-3 pl-2 transition-all text-[16px] leading-[26px] text-[#838189]',
                )}
              >
                {link.label}
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
