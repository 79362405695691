import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from 'src/app/providers/UserProvider';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { Carousel } from 'src/shared/ui/Carousel';
import { ProductGridCard } from 'src/feature/ProductGridCard';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { routesEnum } from 'src/shared/const';
import { addSpaces } from 'src/shared/lib/utils';
import { Button, Img } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';
import styles from './styles.module.css';
import { extractProductValues } from 'src/shared/utils';
import { InfoIcon } from 'src/shared/assets/icons/kit/Info';
import { InfoTooltip } from 'src/shared/ui/InfoTooltip';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { useAddToBasket } from 'src/shared/lib/hooks';
import { GamepadIcon } from 'src/shared/assets/icons/kit/Gamepad';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';
import { sameProductResponsive } from './constants';


export const ProductPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(UserContext);

  const { isMobile } = useIsMobile();

  const [product, setProduct] = useState<ProductType | null>(null);
  const [isInfoIconHover, setIsInfoIconHover] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedDescription, setSelectedDescription] = useState<'description' | 'feature'>(
    'description',
  );
  const [similarProducts, setSimilarProducts] = useState<ProductType[]>([]);

  const { amount } = useAddToBasket({ product });

  const extractedProductValues = useMemo(
    () => extractProductValues(product?.parameters),
    [product?.parameters],
  );
  const screenshotCardSize = { width: 524, height: 295 };

  const onClickToBasket = () => {
    navigate('/basket');
  };

  const handleFavoriteToggle = useCallback(
    (item: ProductType) => {
      setSimilarProducts(prev =>
        prev.map(x => (x.id === item.id ? { ...x, favorite: !x.favorite } : x)),
      );
    },
    [setSimilarProducts],
  );

  useEffect(() => {
    if (!id || !user?.id) return;
    (async () => {
      try {
        setIsLoading(true);
        const { data } = await CatalogService.getProduct(id, user.id);
        setProduct(data[0]);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, user]);

  useEffect(() => {
    if (!product || !id || !user?.id) return;
    (async () => {
      try {
        const { data } = await CatalogService.getSimilarProducts(id, user.id);
        setSimilarProducts(data);
      } catch (e) {}
    })();
  }, [product]);

  const handleFavoriteChange = () => {
    if (product) {
      const productPayload = {
        ...product,
        favorite: !product?.favorite,
      };

      setProduct(productPayload);
    }
  };

  if (!isLoading && !product) {
    return (
      <div className="mx-auto mt-40 flex w-40 flex-col items-center">
        <div className="mb-8 font-medium">Товар не найден</div>
        <Button variant="primary" className="w-full" onClick={() => navigate(routesEnum.CATALOG)}>
          В каталог
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return <Loader classNames="w-20 h-20 mx-auto mt-20 text-primary-100" />;
  }

  return (
    product && (
      <>
        <style>
          {`
            .bgPicture {
              background-position: 50% 0;
              background-image: linear-gradient(rgba(24, 24, 24, 0.4) 20.42%, rgba(24, 24, 24, 0.8) 46.9%, #181818 64.25%, rgba(24, 24, 24, 1) 100%), url('${product.image}');
              background-size: 100%;
              background-repeat: no-repeat;
            }
            
            @media (min-width: 1200px) {
              .bgPicture {
                background-position: center;
                background-image: linear-gradient(90deg, #181818 20.42%, rgba(24, 24, 24, 0.8) 46.9%, rgba(24, 24, 24, 0.4) 64.25%, rgba(24, 24, 24, 0) 100%), url('${product.image}'); 
                background-size: cover;
              }
            }
          `}
        </style>
        <div className="pb-24">
          <div className="bgPicture relative flex w-full items-end gap-8 overflow-hidden bg-gray-900 pt-[241px] d-xs:pt-[271px] mb-[60px] d-xs:pb-[157px]">
            <div className="d-xs:max-w-[1720px] w-full d-xl:m-auto">
              <div
                className={`z-100 flex flex-col text-left text-white px-4 d-xl:px-0 max-w-[960px]`}
              >
                <div className="w-fit -skew-x-12 transform rounded-md bg-orange-200 px-[7px] text-white mb-2 d-xs:mb-[10px] ml-[3px] d-xs:py-0.5 d-xs:px-4">
                  <span className="font-default-medium block skew-x-12 transform py-[9px] p-[13px] text-[14px] leading-[14px]">
                    эксклюзивный товар
                  </span>
                </div>

                <div className="text-[22px] d-xs:text-[64px] font-default-bold line-clamp-2 leading-[30px] d-xs:leading-[80px] mb-[10px] d-xs:mb-[20px]">{product.name}</div>

                <div className="flex items-center gap-3 d-xs:gap-5 text-gray-400 mb-[22px] d-xs:mb-6">
                  <img src={product.shop.photo} className="h-9 w-9" alt=''/>

                  <span className="flex items-center text-[12px] d-xs:text-[30px] font-default-medium">
                    {product.shop.name}
                  </span>

                  <span className="flex text-[12px] d-xs:text-[30px] font-default-medium items-center before:content-[''] before:block before:w-1 d-xs:before:w-2 before:h-1 d-xs:before:h-2 before:bg-[#a6a6a6] before:mr-[5px] xl:before:mr-5 first:before:hidden">
                    {extractedProductValues?.genre}
                  </span>
                </div>

                <div className="flex mb-[18px] d-xs:mb-[26px]">
                  <div
                    className="font-default-medium flex items-center text-gray-400 text-[12px] d-xs:text-[30px] mr-1 d-xs:mr-2"
                  >
                    {extractedProductValues?.platform}
                  </div>

                  <div
                    className="font-default-medium flex items-center text-gray-400 ml-[12px] text-[12px] d-xs:text-[30px] before:content-[''] before:block before:w-1 d-xs:before:w-2 before:h-1 d-xs:before:h-2 before:bg-[#a6a6a6] before:mr-[16px] xl:before:mr-5 first:before:hidden"
                  >{extractedProductValues?.region}
                  </div>
                </div>

                <div
                  className="line-clamp-6 text-[15px] d-xs:line-clamp-3 dx-s:text-[24px] leading-[19px] font-default-regular d-xs:text-[24px] d-xs:leading-[30px] d-xs:mb-[48px]"
                >
                  {product.description}
                </div>

                <div className="flex items-center gap-3 mb-[17px] d-xs:mb-[48px]">
                  <div className="font-default-medium text-[40px]">{`${addSpaces(+product.newPrice.replace(',', '.'))} ₽`}</div>
                  {product.discount.toString() !== '0' && product.discount && (
                    <div className="font-default-regular text-sm text-gray-400 line-through">{`${addSpaces(+product.oldPrice.replace(',', '.'))} ₽`}</div>
                  )}
                  {product.discount.toString() !== '0' && product.discount && (
                    <div className="inline-block -skew-x-12 transform rounded-md bg-midGray px-2 text-white">
                      <span className="font-default-medium block skew-x-12 transform text-lg ">
                        -{product.discount}%
                      </span>
                    </div>
                  )}
                  {!isMobile && (
                    <InfoTooltip content="Цифровые товары не подлежат возврату, если они надлежащего качества">
                      <div
                        className="z-100"
                        onMouseEnter={() => setIsInfoIconHover(true)}
                        onMouseLeave={() => setIsInfoIconHover(false)}
                      >
                        <InfoIcon isInfoIconHover={isInfoIconHover} />
                      </div>
                    </InfoTooltip>
                  )}
                </div>

                <div
                  className="align-center flex gap-4 d-xs:gap-5 mb-5"
                >
                  <ProductAddButton product={product} className="flex w-[208px] h-[44px] d-xs:h-[40px] d-xs:w-[325px] justify-center" />

                  {amount && !isMobile ? (
                    <div className="flex gap-3">
                      <Button
                        variant="primary"
                        className="h-full flex justify-center w-[235px]"
                        onClick={onClickToBasket}
                      >
                        Перейти в корзину
                      </Button>
                      <Button
                        variant="blackOutline"
                        className="flex justify-center gap-1 w-[235px]"
                        onClick={() => navigate('/')}
                      >
                        <GamepadIcon />
                        Продолжить покупки
                      </Button>
                    </div>
                  ) : null}

                  {isMobile ? (
                    <div className="flex items-center gap-4">
                      <FavoriteButton onClick={handleFavoriteChange} data={product} isProduct classNames="w-[44px] h-[44px] d-xs:w-[40px] d-xs:h-[40px]" />

                      <InfoTooltip content="Цифровые товары не подлежат возврату, если они надлежащего качества">
                        <div
                          className="z-100"
                          onMouseEnter={() => setIsInfoIconHover(true)}
                          onMouseLeave={() => setIsInfoIconHover(false)}
                        >
                          <InfoIcon isInfoIconHover={isInfoIconHover} className="w-[44px] h-[44px]"/>
                        </div>
                      </InfoTooltip>
                    </div>
                  ) : (
                    <FavoriteButton onClick={handleFavoriteChange} data={product} isProduct classNames="w-[44px] h-[44px] d-xs:w-[40px] d-xs:h-[40px]" />
                  )}
                </div>

                {amount && isMobile ? (
                  <div className="flex flex-col gap-[12px]">
                    <Button
                      variant="primary"
                      className="w-full h-full flex justify-center"
                      onClick={onClickToBasket}
                    >
                      Перейти в корзину
                    </Button>
                    <Button
                      variant="blackOutline"
                      className="w-full flex justify-center gap-1"
                      onClick={() => navigate('/')}
                    >
                      <GamepadIcon />
                      Продолжить покупки
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {!!product.carouselImages.length && (
            <div className={`max-w-[1720px] mx-auto mt-8 px-4 d-xl:px-0 mb-[60px] d-xs:mb-[100px]`}>
              <div className={`mb-[18px] d-xs:mb-6 font-medium text-[24px] d-xs:text-[32px]`}>
                Скриншоты
              </div>
              <Carousel
                items={product.carouselImages.map((img, i) => (
                  <div key={`img-${i}`} style={screenshotCardSize}>
                    <Img src={img} classNames="object-cover" style={screenshotCardSize} />
                  </div>
                ))}
                slidesToScroll={1}
                variableWidth={true}
                className={styles.slider}
              />
            </div>
          )}

          <div className="mx-auto max-w-[1720px] px-4 d-xl:px-0 d-xs:pt-[34px]">
            <div className="font-default-medium text-[24px] leading-[32px] mb-[22px] d-xs:font-default-semibold d-xs:leading-[20px] d-xs:text-[32px] d-xs:mb-[30px]">
              Подробности
            </div>

            {/*TODO: В будущем стоит переделать на гриды, чем дублировать код*/}

            {isMobile && (
              <div className="mb-6 flex gap-[24px]">
                <div
                  onClick={() => setSelectedDescription('description')}
                  className={`font-default-regular ${selectedDescription === 'description' && 'border-b-3 border-scarlet-200 pb-[12px]'}`}
                >
                  Описание
                </div>
                <div
                  onClick={() => setSelectedDescription('feature')}
                  className={`font-default-regular ${selectedDescription === 'feature' && 'border-b-3 border-scarlet-200 pb-[16px]'}`}
                >
                  Характеристики
                </div>
              </div>
            )}

            <div className="rounded-[24px] bg-darkGray p-4 d-xs:h-auto d-xs:py-[35px] d-xs:px-[60px] d-xs:rounded-[40px] ">
              <div className="flex flex-col gap-8 d-xs:flex-row">
                <div
                  className={`flex-1 ${isMobile && selectedDescription === 'feature' ? 'hidden' : ''}`}
                >
                  {!isMobile && <div className="mb-8 text-base text-textGray">Описание</div>}
                  {isMobile && selectedDescription === 'description' && (
                    <div className="whitespace-pre-line font-default-regular text-[14px] leading-[18px]">{product.description}{product.description}</div>
                  )}
                  {!isMobile && (
                    <div className="whitespace-pre-line text-base font-default-regular">{product.description}{product.description}</div>
                  )}
                </div>
                <div
                  className={`flex-1 ${isMobile && selectedDescription === 'description' ? 'hidden' : ''}`}
                >
                  {!isMobile && <div className="mb-[26px] text-base text-textGray">Характеристики</div>}
                  {isMobile && selectedDescription === 'feature' && (
                    <div className="gap-4 font-default-regular">
                      {product.parameters.map(parameter => (
                        <div key={parameter.parameterId} className="mt-4 first-of-type:mt-0 d-xs:first-of-type:mt-4 flex flex-col">
                          <span className="text-textGray font-default-regular text-[16px] leading-[20px]">{parameter.parameterName}</span>
                          <span className="text-[16px] leading-[20px]">{parameter.parameterValues.map(v => v.valueName).join(', ')}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  {!isMobile && (
                    <div className="gap-4 text-base">
                      {product.parameters.map(parameter => (
                        <div key={parameter.parameterId} className="mt-5 flex flex-col">
                          <span className="text-textGray font-default-regular leading-[20px]">{parameter.parameterName}</span>
                          <span className="text-[16px] leading-[20px]">{parameter.parameterValues.map(v => v.valueName).join(', ')}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 mx-auto max-w-[1720px] px-[16px] d-xl:px-0">
            <div className={`mb-3 font-medium ${isMobile ? 'text-[24px]' : 'text-[32px]'}`}>
              Похожие товары
            </div>
            <Carousel
              items={similarProducts.map(el => (
                <ProductGridCard
                  key={el.id}
                  data={el}
                  onFavorite={handleFavoriteToggle}
                  cardStyles={{ width: '100%', height: '100%' }}
                />
              ))}
              slidesToScroll={1}
              slidesToShow={5}
              variableWidth={false}
              className={styles.slider}
              responsive={sameProductResponsive}
              dots={false}
            />
          </div>
        </div>
      </>
    )
  );
};
