import React, { FC } from 'react';
import { Article } from "src/feature/ArticlesBlock/model/types";
import { ReactComponent as Comment } from 'src/shared/assets/icons/kit/Comment.svg';

interface SliderCardProps {
  article: Article;
  className?: string;
}

export const ArticleCard: FC<SliderCardProps> = ({ article, className = '' }) => {
  return (
    <div className={`overflow-hidden ${className}`}>
      <img
        src={article.imageUrl}
        alt={article.title}
        className="w-full h-[328px] md:h-[408px] object-cover rounded-[20px] d-xs:mb-[10px]"
      />
      <div className="p-4">
        <div className="p-4 pt-[13px] pb-4">
          <h3 className="font-default-semibold text-[18px] d-xs:text-[22px] leading-[23px] d-xs:leading-[26px] mb-[10px] d-xs:mb-[18px] line-clamp-2">{article.title}</h3>
          <p className="font-default-regular text-[12px] d-xs:text-[16px] leading-[15px] d-xs:leading-[20px] mb-4 d-xs:mb-[20px] line-clamp-3">{article.description}</p>
          <span className="flex items-center">
            <Comment className="mr-[5px]"/>
            {article.commentsCount} comments</span>
          <span>{article.publishDate}</span>
        </div>
      </div>
    </div>
  );
};