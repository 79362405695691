import { ChangeEvent, FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { CheckBox } from 'src/shared/ui';

interface FilterCheckBoxListProps {
  title: string;
  activeFilters: string[];
  list: string[];
  setFilter: (title: string, filter: string) => void;
  classNames?: string;
}

export const FilterCheckBoxList: FC<FilterCheckBoxListProps> = ({
  title,
  activeFilters,
  list,
  setFilter,
  classNames,
}) => {
  const [isAll, setIsAll] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const filteredList = useMemo(() => {
    if (isAll && search) {
      return list.filter(el => el.toLowerCase().includes(search.toLowerCase()));
    }

    if (isAll) {
      return list;
    }

    return list.slice(0, 6);
  }, [isAll, list, search]);

  const handleClick = () => {
    isAll && setSearch('');
    setIsAll(prev => !prev);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  return (
    <div className={cn('w-full', classNames)}>
      <div className="font-default-regular mb-5 text-sm">{title}</div>

      {isAll && (
        <input
          className={
            'font-default-regular mb-4 h-9 w-full rounded-md border border-grey-400 px-3 text-sm'
          }
          placeholder="Поиск"
          onChange={handleSearch}
        />
      )}

      <div className="mb-2 max-h-44 overflow-auto">
        {!filteredList?.length ? (
          <div className="font-default-regular text-sm">Ничего не найдено</div>
        ) : null}

        {filteredList?.length
          ? filteredList.map(item => (
              <div
                className="group mb-[10px] flex cursor-pointer items-center"
                key={`${title}-${item}`}
                onClick={() => setFilter(title, item)}
              >
                <CheckBox isActive={activeFilters.includes(item)} />
                <div className="font-default-regular ml-4 text-sm">{item}</div>
              </div>
            ))
          : null}
      </div>

      {list.length > 6 ? (
        <button
          className="font-default-regular text-primary-100 cursor-pointer text-sm"
          onClick={handleClick}
        >
          {isAll ? 'Скрыть' : 'Показать еще'}
        </button>
      ) : null}
    </div>
  );
};
