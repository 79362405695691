import { CSSProperties, FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './styles.module.css';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { ProductType } from 'src/shared/api/catalog';
import { addSpaces } from 'src/shared/lib/utils';
import { CardImgCarousel, DiscountPercent, DiscountPrice } from 'src/shared/ui';
import { FavoriteButton } from 'src/entity/FavoriteButton';

interface ProductGridCardProps {
  data: ProductType;
  onFavorite: (data: ProductType) => void;
  cardStyles?: CSSProperties;
}

export const ProductGridCard: FC<ProductGridCardProps> = ({ data, onFavorite, cardStyles }) => {
  const {
    name,
    id,
    newPrice,
    carouselImages,
    rating,
    commentsCount,
    image,
    parameters,
    oldPrice,
    discount,
  } = data;
  const owner = parameters.find(el => el.parameterName === 'Разработчик');

  const productCardSize = useMemo(() => cardStyles || { width: 213, height: 284 }, [cardStyles]);

  const carousel = useMemo(() => {
    const res: string[] = [];

    if (image) {
      res.push(image);
    }

    if (carouselImages?.length) {
      res.push(...carouselImages);
    }

    return res?.length ? res : [''];
  }, [carouselImages, image]);

  return (
    <div
      className={cn(
        styles.wrapper,
        `relative flex min-h-36 flex-col ${!cardStyles && 'max-w-[213px]'}`,
      )}
      style={productCardSize}
    >
      <div className="absolute right-[10px] top-[10px] d-xs:right-0 d-xs:top-0  z-30 rounded-full bg-bgPrimary p-1.5">
        <FavoriteButton onClick={onFavorite} data={data} />
      </div>

      <Link
        to={`/product/${id}`}
        className="aspect-9/16 x-ss:min-h-[200px] d-xl:h-[316px] h-full w-full overflow-hidden rounded-[4px]"
      >
        <CardImgCarousel images={carousel} />
      </Link>

      <div className="flex w-[100%] flex-col">
        <div className="mt-[10px] d-xs:mt-[6px]">
          <Link to={`/product/${id}`} className="font-default-semibold text-[14px] d-xl:text-[16px] line-clamp-2 h-[42px] d-xl:h-[48px]">
            {name}
          </Link>
        </div>
        <div className="mt-2 d-xl:mt-2">
        <div className="flex items-center gap-2 font-default-regular text-[16px] leading-[20px]">
            {discount.toString() !== '0' && discount ? (
              <div className="hidden d-xs:flex h-5 flex-col justify-center">
                <DiscountPercent percent={discount} />
              </div>
            ) : null}

            {discount.toString() !== '0' && discount && (
              <div className="h-4">
                <DiscountPrice price={oldPrice} />
              </div>
            )}

            <div className="flex items-center">
              <div className="mr-2">{`${addSpaces(+newPrice.replace(',', '.'))} ₽`}</div>
              {/*<SberSpasibo discount={10} price={200} />*/}
            </div>
          </div>

          {/* Тут были звездочки */}
          {/*<div className="mb-2 flex items-center">*/}
          {/*  <Stars rate={rating} />*/}
          {/*  <div className="font-default-medium ml-2 mt-1 text-xs">{commentsCount}</div>*/}
          {/*</div>*/}

          {/* TODO: понять нужно ли это */}
          {/* <div
            className={cn(
              { 'opacity-0': !Boolean(owner?.parameterValues[0]?.valueName) },
              'font-default-medium mb-2 truncate whitespace-nowrap text-xs text-grey-500',
            )}
          >
            {`Разработчик: ${owner?.parameterValues[0]?.valueName || ''}`}
          </div> */}

          <ProductAddButton product={data} className="mt-[10px] d-xl:mt-[15px] py-[12px] d-xl:py-[9px] w-full" marginTopAmount="2" />
        </div>
      </div>
    </div>
  );
};
